import { Routes, Route } from "react-router-dom";

import NotFound from "../pages/NotFound";
import Welcome from "../pages/Welcome";
import Projects from "../pages/Projects";
import Project from "../pages/Project";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Welcome />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/projects/:projectId" element={<Project />} />
      <Route path="*" element={<NotFound />} status={404} />
    </Routes>
  );
};

export default AppRoutes;
