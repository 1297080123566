import { useEffect, useRef, useState } from "react";

const AboutComp = ({ item, giveRef }) => {
  const { reverse, svg, items, description, title } = item;
  return (
    <div
      ref={giveRef}
      className={reverse ? "about-item reverse" : "about-item"}
    >
      <div className="about-item--illustration">{svg}</div>
      <div className="about-item--description">
        <h4>{title}</h4>
        <p>{description}</p>
        {items.map((item, index) => (
          <AboutMiniItem key={index} item={item} />
        ))}
      </div>
    </div>
  );
};

const AboutMiniItem = ({ item }) => {
  const [boxIsOpen, setBoxIsOpen] = useState(false);
  const { img, title, description } = item;
  const aboutBoxRef = useRef(null);

  useEffect(() => {
    const handleOutSideClick = (event) => {
      if (!aboutBoxRef.current?.contains(event.target) && boxIsOpen) {
        setBoxIsOpen(false);
      }
    };
    window.addEventListener("mousedown", handleOutSideClick);
    return () => {
      window.removeEventListener("mousedown", handleOutSideClick);
    };
  }, [aboutBoxRef, boxIsOpen]);

  return (
    <div
      className={boxIsOpen ? "about-box open" : "about-box"}
      ref={aboutBoxRef}
    >
      <div
        className="about-box--header"
        onClick={() => setBoxIsOpen(!boxIsOpen)}
      >
        <img src={img} alt="" />
        <p>{title}</p>
        <i className="fa-solid fa-circle-chevron-down"></i>
      </div>
      {boxIsOpen && (
        <div className="about-box--body">
          <p>{description}</p>
        </div>
      )}
    </div>
  );
};

export default AboutComp;
