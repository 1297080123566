import { useState } from "react";

const FormFields = ({ id, type, label, foncChange }) => {
  const [empty, setEmpty] = useState("empty");
  const [isError, setIsError] = useState(null);
  const [fieldText, setFieldText] = useState("");

  let inputHTML, checker, errorMsg;

  const handleChange = () => {
    if (fieldText === "" || fieldText === null) {
      setEmpty("empty");
      setIsError(null);
      foncChange(id, null);
    } else {
      setEmpty("not-empty");
      if (!checker.test(fieldText)) {
        setIsError(errorMsg);
        foncChange(id, null);
      } else {
        setIsError(null);
        foncChange(id, fieldText);
      }
    }
  };

  // const handleChange = (e) => {
  //   let str = e.target.value;
  //   if (e.target.value === "" || e.target.value === null) {
  //     setEmpty("empty");
  //     setIsError(null);
  //     foncChange(id, null);
  //   } else {
  //     setEmpty("not-empty");
  //     if (!checker.test(str)) {
  //       setIsError(errorMsg);
  //       foncChange(id, null);
  //     } else {
  //       setIsError(null);
  //       foncChange(id, str);
  //     }
  //   }
  // };

  if (type === "text") {
    inputHTML = (
      <input
        name={id}
        type="text"
        className="field-item--input"
        onBlur={(e) => handleChange(e)}
        onChange={(e) => setFieldText(e.target.value)}
        value={fieldText || ""}
      />
    );

    checker = new RegExp(/^.{2,}$/);
    errorMsg = "Ce champ est obligatoire.";
  } else if (type === "textarea") {
    inputHTML = (
      <textarea
        name={id}
        type="text"
        className="field-item--input"
        onBlur={(e) => handleChange(e)}
        onChange={(e) => setFieldText(e.target.value)}
        value={fieldText || ""}
      ></textarea>
    );

    checker = new RegExp(/^.{3,}$/s);
    errorMsg = "Ce champ est obligatoire.";
  } else if (type === "name") {
    inputHTML = (
      <input
        name={id}
        type="text"
        className="field-item--input"
        onBlur={(e) => handleChange(e)}
        onChange={(e) => setFieldText(e.target.value)}
        value={fieldText || ""}
      />
    );

    checker = new RegExp(
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/
    );
    errorMsg = "Ce nom est incorrect !";
  } else if (type === "email") {
    inputHTML = (
      <input
        name={id}
        type="text"
        className="field-item--input"
        onBlur={(e) => handleChange(e)}
        onChange={(e) => setFieldText(e.target.value)}
        value={fieldText || ""}
      />
    );

    //eslint-disable-next-line
    checker = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
    errorMsg = "Le format de l'email est incorrect (ex: john.doe@dom.com)";
  } else if (type === "tel") {
    inputHTML = (
      <input
        name={id}
        type="tel"
        className="field-item--input"
        onBlur={(e) => handleChange(e)}
        onChange={(e) => setFieldText(e.target.value)}
        value={fieldText || ""}
      />
    );

    checker = new RegExp(
      //eslint-disable-next-line
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
    );
    errorMsg = "Le format du numéro est incorrect (ex: +33600000001)";
  }

  return (
    <div
      className={isError ? `field-item ${empty} error` : `field-item ${empty}`}
    >
      <p className="field-item--label">{label}</p>
      {inputHTML}
      {isError ? <span className="field-item--errormsg">{isError}</span> : null}
    </div>
  );
};

export default FormFields;
