export const icons = {
  CSS: {
    path: "CSS.png",
    link: "https://developer.mozilla.org/fr/docs/Web/CSS",
  },
  DJANGO: {
    path: "DJANGO.png",
    link: "https://www.djangoproject.com/",
  },
  CHROME: {
    path: "CHROME.png",
    link: "https://developer.chrome.com/docs/devtools/",
  },
  FIGMA: {
    path: "FIGMA.png",
    link: "https://www.figma.com/",
  },
  HTML: {
    path: "HTML.png",
    link: "https://developer.mozilla.org/fr/docs/Web/HTML",
  },
  JAVASCRIPT: {
    path: "JAVASCRIPT.png",
    link: "https://developer.mozilla.org/fr/docs/Web/JavaScript",
  },
  PYTHON: {
    path: "PYTHON.png",
    link: "https://www.python.org/",
  },
  REACT: {
    path: "REACT.png",
    link: "https://fr.legacy.reactjs.org/",
  },
  SASS: {
    path: "SASS.png",
    link: "https://sass-lang.com/",
  },
  VSCODE: {
    path: "VSCODE.png",
    link: "https://code.visualstudio.com/",
  },
  GITHUB: {
    path: "GIT.png",
    link: "https://github.com/",
  },
  UML: {
    path: "UML.png",
    link: "https://fr.wikipedia.org/wiki/UML_(informatique)",
  },
  NODEJS: {
    path: "NODEJS.png",
    link: "https://nodejs.org/en",
  },
  SWAGGER: {
    path: "SWAGGER.png",
    link: "https://swagger.io/",
  },
  FLASK: {
    path: "FLASK.png",
    link: "https://flask.palletsprojects.com/en/",
  },
  POSTMAN: {
    path: "POSTMAN.png",
    link: "https://www.postman.com/",
  },
  DOCKER: {
    path: "DOCKER.png",
    link: "https://www.docker.com/",
  },
};
