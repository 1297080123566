import { Link, useParams } from "react-router-dom";
import NotFound from "./NotFound";
import data from "../assets/data/projects.json";
import { icons } from "../assets/imgs/icons/icons";
import Modal from "../components/Modal";
import { useState } from "react";
import ImageZoom from "react-image-zooom";

const Project = () => {
  let { projectId } = useParams();
  const proj_data = data.projects.find((item) => item.id === +projectId);
  const [openModal, setOpenModal] = useState(null);

  if (!proj_data) {
    return <NotFound />;
  }

  document.title = `${proj_data.name} - Mahery Gonin`;

  return (
    <>
      {openModal ? (
        <Modal content={openModal} closeCmd={() => setOpenModal(null)} />
      ) : null}
      <div className="project-page">
        <img
          className="project-page--logo"
          src={require(`../assets/imgs/project_imgs/${proj_data.logo}`)}
          alt={`Logo of ${proj_data.name}`}
        />
        <div className="project-page--content">
          <div className="project-page--content--colum1">
            <div className="project-page--content--colum1--description">
              <h3>Le projet</h3>
              <p>{proj_data.description}</p>
            </div>
            <div className="project-page--content--colum1--skills">
              <h3>Compétences acquises</h3>
              {proj_data.skills.map((skill, index) => (
                <p key={index}>- {skill}</p>
              ))}
            </div>
            {proj_data.imgs ? (
              <div className="project-page--content--colum1--imgs">
                <h3>Images</h3>
                <div className="project-page--content--colum1--imgs-container">
                  {proj_data.imgs.map((item, index) => (
                    <div
                      key={`${item}-${index}`}
                      className="img-container"
                      onClick={() =>
                        setOpenModal(
                          <ImageZoom
                            className="modal--content"
                            src={require(`../assets/imgs/project_imgs/${proj_data.name}/${item}`)}
                            zoom="150"
                            alt={`${proj_data.name} ${item}`}
                          />
                        )
                      }
                    >
                      <img
                        key={`imgs-${item}-${index}`}
                        src={require(`../assets/imgs/project_imgs/${proj_data.name}/${item}`)}
                        alt={`${proj_data.name} ${item}`}
                      />
                      <i className="fa-solid fa-eye"></i>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>

          <div className="project-page--content--infos">
            <div className="infos-sections">
              <h4>Liens</h4>
              <div className="infos-sections--icons">
                <Link
                  to={proj_data.github}
                  target={"_blank"}
                  className="project-page--content--infos--links--icon"
                >
                  <i className="fa-brands fa-github"></i>
                </Link>
                {proj_data.link ? (
                  <Link
                    to={proj_data.link}
                    target={"_blank"}
                    className="project-page--content--infos--links--icon"
                  >
                    <i className="fa-solid fa-up-right-from-square"></i>
                  </Link>
                ) : null}
              </div>
            </div>
            <InfosSection name="Langages" list={proj_data.langages} />
            <InfosSection name="Outils" list={proj_data.tools} />
          </div>
        </div>
      </div>
    </>
  );
};

const InfosSection = ({ name, list }) => {
  return (
    <div className="infos-sections">
      <h4>{name}</h4>
      <div className="infos-sections--icons">
        {list.map((item, index) => (
          <Link
            key={`${item}-${index}`}
            to={icons[item].link}
            target={"_blank"}
            className="infos-sections--icon"
          >
            <img
              src={require(`../assets/imgs/icons/${icons[item].path}`)}
              alt={`Icon of ${item}`}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Project;
