import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import MediaQuery from "react-responsive";

import "./sass/index.scss";

import AppRoutes from "./utils/AppRoutes";
import Banner from "./components/Banner";
import MobileHeader from "./components/MobileHeader";
import ScrollToTop from "./components/ScrollToTop";

import ReactGA from "react-ga4";
import GAnalyticsService from "./utils/GAnalyticsService";

const GA_ID = process.env.REACT_APP_GA_MESUREID;
ReactGA.initialize(GA_ID);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <GAnalyticsService />
      <ScrollToTop />
      <MediaQuery minWidth={1300}>
        <Banner />
      </MediaQuery>
      <MediaQuery maxWidth={1300}>
        <MobileHeader />
      </MediaQuery>
      <AppRoutes />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
