import { ReactComponent as Developer } from "../imgs/developer.svg";
import { ReactComponent as Associatif } from "../imgs/associat.svg";
import { ReactComponent as Informatique } from "../imgs/informatique.svg";
import openclassrooms_logo from "../imgs/icons/OpenClassrooms.png";
import avsa_logo from "../imgs/icons/logo-avsa.png";
import bde_logo from "../imgs/icons/logo_bde.png";
import ocaly_logo from "../imgs/icons/ocaly_logo.png";
import sepr_logo from "../imgs/icons/sepr_logo.png";
import dessimal_logo from "../imgs/icons/dessimal_logo.png";
import dea_logo from "../imgs/icons/dea_logo.png";

export const AboutMe = [
  {
    title: "Développement",
    reverse: false,
    svg: <Developer />,
    description:
      "Le développement a attiré mon attention depuis mon plus jeune âge. Dès mes 13 ans, je commençais à développer des minigames sur le célèbre jeu Minecraft accompagné de son site WordPress. C'est là que j'ai découvert mon épanouissement dans la programmation... Pour rendre tout ça plus concret je me suis formé aux technologies d'aujourd'hui tel que Python et React pour avoir un panel de possibilités infinies.",
    items: [
      {
        img: openclassrooms_logo,
        title: "OpenClassrooms - Développeur concepteur de logiciel",
        description:
          "Sur les années 2022 - 2023: Obtention d'un diplôme de niveau master (niv. 6) sur une formation orientée frontend avec une forte utilisation du framework React. Réalisations d'une dizaine de projets disponibles dans l'onglet 'Mes projets'.",
      },
      {
        img: openclassrooms_logo,
        title: "OpenClassrooms - Développeur d'application Python",
        description:
          "Sur les années 2020 - 2021: Obtention d'un diplôme de niveau master (niv. 6) sur une formation orientée backend en python avec une forte utilisation du framework Django. Réalisations d'une dizaine de projets disponibles dans l'onglet 'Mes projets'. Créations de plusieurs applications (API Rest, DJango, Flask, Terminal, Algos, SQL...), scraping, méthode agile, déploiement, CI/CD, TDD, UML, documentations.",
      },
    ],
  },
  {
    title: "Informatique",
    reverse: true,
    svg: <Informatique />,
    description:
      "L'informatique a été pour moi, une passerelle vers la programmation. Pour atteindre mon objectif de devenir développeur, j'ai commencé par me former dans l'informatique en général.",
    items: [
      {
        img: dea_logo,
        title: "DEA Informatique - Technicien Informatique",
        description:
          "De 2016 à 2018 : j'ai exercé en tant que technicien informatique dans l'entreprise DEA informatique / City Computer. C'est une société qui fait de la vente de matériel et prestations informatiques pour les particuliers et les professionnels. Installations et configurations de postes de travail, systèmes d’encaissement, vidéo-surveillance, logiciels, maintenance.",
      },
      {
        img: dessimal_logo,
        title: "Dessimal - Technicien Informatique",
        description:
          "Sur l'année 2019, j'ai exercé en tant que technicien informatique dans l'entreprise Dessimal. C'est une société qui propose des solutions informatiques au service des entreprises. Installations et configurations de postes de travail, imprimantes, solutions d’encaissement, vidéo-surveillance, logiciels métiers, maintenance.",
      },
      {
        img: sepr_logo,
        title: "SEPR Lyon - BAC SN RISC",
        description:
          "De 2016 à 2019 : Obtention du BAC Système Numérique option Réseaux Informatiques et Systèmes Communicants mention bien. Récompensé par le prix d'honneur pour l'année 2017-2018. Formation au métier de technicien informatique et numérique. Télécommunications et réseaux. Électronique industrielle et embarquée.",
      },
    ],
  },
  {
    title: "Associatif",
    reverse: false,
    svg: <Associatif />,
    description:
      "Faire partie d'une association a toujours été au cœur de ma vie.",
    items: [
      {
        img: ocaly_logo,
        title: "Ocaly - Matériel scolaire",
        description:
          "De 2017 à 2019 : Co-fondateur et secrétaire de l'association Ocaly récompensée par le prix Félix Rollet et le Rotary Club de Lyon. Achat et vente de manuels scolaires au sein d'un établissement afin de permettre aux étudiants de payer leurs manuels à bas prix et de les revendre l'année qui suit. Le tout à travers un site internet qui trace et organise le stock des manuels.",
      },
      {
        img: avsa_logo,
        title: "AVSA - Association animalière",
        description:
          "Depuis 2023 : « Association Varoise de Secours aux Animaux ». Refuge pour chiens abandonnés et délaissés. J'y suis bénévole et je promène régulièrement ces chiens pour leur apporter un peu de compagnie et leur permettre de se défouler.",
      },
      {
        img: bde_logo,
        title: "BDE SEPR - Association vie étudiante",
        description:
          "De 2017 à 2019 : Adhésion au conseil d'administration de l'association du BDE de la SEPR de Lyon, rôle de secrétaire. Organisation d'événements pour dynamiser la vie étudiante.",
      },
    ],
  },
];
