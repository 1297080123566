import { useEffect } from "react";

const Modal = ({ closeCmd, content }) => {
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Escape") {
        closeCmd();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
    //eslint-disable-next-line
  }, []);

  return (
    <div className="modal">
      <i
        className="modal--close fa-solid fa-xmark"
        onClick={() => closeCmd()}
      ></i>
      {content}
    </div>
  );
};

export default Modal;
