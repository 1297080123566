import { ReactComponent as Img } from "../../assets/imgs/send.svg";

const SuccessSend = () => {
  return (
    <div className="form-send-thanks">
      <Img />
      <h4 className="form-send-thanks--title">Merci !</h4>
      <p className="form-send-thanks--msg">
        Merci de l'intérêt que vous portez à mon profil ! L'envoi a bien
        fonctionné.
        <br />
        Je répondrais à votre message dans les meilleurs délais.
      </p>
    </div>
  );
};

export default SuccessSend;
