import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const [activeScrollBtn, setActiveScrollBtn] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 200) {
        setActiveScrollBtn(true);
      } else {
        setActiveScrollBtn(false);
      }
    });
  }, []);

  return (
    <>
      {activeScrollBtn ? (
        <i
          onClick={() => window.scrollTo(0, 0)}
          className="fa-solid fa-circle-chevron-up scroll-to-top-btn"
        ></i>
      ) : null}
    </>
  );
};

export default ScrollToTop;
