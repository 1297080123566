import TitlePage from "../components/TitlePage";
import projects_data from "../assets/data/projects.json";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { ReactComponent as NoData } from "../assets/imgs/no-data.svg";

const Projects = () => {
  document.title = "Projets - Mahery Gonin";
  const [filterList, setFilterList] = useState({
    langages: [],
    tools: [],
  });
  const [filters, setFilter] = useState({
    langages: [],
    tools: [],
  });

  const [projectsList, setProjectsList] = useState(projects_data.projects);

  /* Create Filters list for components */
  useEffect(() => {
    let lang_lst = [];
    projectsList.forEach((item) => {
      item.langages.forEach((lang) => {
        if (!lang_lst.includes(lang)) {
          lang_lst.push(lang);
        }
      });
    });

    let tool_lst = [];
    projectsList.forEach((item) => {
      item.tools.forEach((tool) => {
        if (!tool_lst.includes(tool)) {
          tool_lst.push(tool);
        }
      });
    });
    setFilterList({ ...filterList, langages: lang_lst, tools: tool_lst });
    //eslint-disable-next-line
  }, []);

  const addToFilter = (type, item) => {
    setFilter({ ...filters, [type]: [...filters[type], item] });
    // updateProjectsList();
  };

  const removeFromFilter = (type, item) => {
    setFilter({
      ...filters,
      [type]: filters[type].filter((itemIter) => itemIter !== item),
    });
  };

  useEffect(() => {
    if (filters.langages.length > 0 || filters.tools.length > 0) {
      let notSortedList = projects_data.projects;
      for (const [key, value] of Object.entries(filters)) {
        //eslint-disable-next-line
        value.map((item) => {
          notSortedList = notSortedList.filter((value) =>
            value[key].includes(item)
          );
        });
      }
      setProjectsList(notSortedList);
    } else {
      setProjectsList(projects_data.projects);
    }
  }, [filters]);

  return (
    <>
      <div className="projects-page">
        <TitlePage text="Mes projets" />
        <div className="projects-page--filters">
          <div className="projects-page--filters-langages">
            {filterList.langages.map((item, index) => (
              <FilterItem
                key={index}
                type="langages"
                item={item}
                add={() => addToFilter("langages", item)}
                remove={() => removeFromFilter("langages", item)}
              />
            ))}
          </div>
          <div className="projects-page--filters-tools">
            {filterList.tools.map((item, index) => (
              <FilterItem
                key={index}
                type="tools"
                item={item}
                add={() => addToFilter("tools", item)}
                remove={() => removeFromFilter("tools", item)}
              />
            ))}
          </div>
        </div>
        <div className="projects-page--content">
          {projectsList.length > 0 ? (
            projectsList.map((element, index) => (
              <ProjectItem key={`${element.name}-${index}`} element={element} />
            ))
          ) : (
            <p className="not-projects-found">
              <NoData />
              Il n'y a aucun projet disponible avec ces filtres choisis.
              <br />
              Merci de sélectionner différents filtres.
            </p>
          )}
        </div>
      </div>
    </>
  );
};

const FilterItem = ({ type, item, add, remove }) => {
  const [isActive, setIsActive] = useState(false);
  const activateMe = () => {
    if (isActive) {
      setIsActive(false);
      remove();
    } else {
      setIsActive(true);
      add();
    }
  };
  return (
    <p
      onClick={() => activateMe()}
      className={isActive ? `${type} active` : `${type}`}
    >
      {item}
    </p>
  );
};

const ProjectItem = ({ element }) => {
  return (
    <Link to={`./${element.id}`}>
      <div className="projects-page--content--proj-item">
        <img
          className="projects-page--content--proj-item--img"
          src={require(`../assets/imgs/project_imgs/${element.logo}`)}
          alt={`Logo of ${element.name}`}
        />
        <p className="projects-page--content--proj-item--project-type">
          {element.project_type}
        </p>
        <div className="projects-page--content--proj-item--langages">
          {element.langages.map((icon, index) => (
            <img
              key={`${icon}-${index}`}
              className="projects-page--content--proj-item--langages--icon"
              src={require(`../assets/imgs/icons/${icon}.png`)}
              alt={`icon of ${icon}`}
            />
          ))}
        </div>
      </div>
    </Link>
  );
};

export default Projects;
