import emailjs from "@emailjs/browser";

const EMAILJS_SERVICE = process.env.REACT_APP_EMAILJS_SERVICEID;
const EMAILJS_TEMPLATE = process.env.REACT_APP_EMAILJS_TEMPLATEID;
const EMAILJS_PUBLICKEY = process.env.REACT_APP_EMAILJS_PUBLICKEY;

const EmailService = async (form) => {
  let isSuccess = null;
  await emailjs
    .sendForm(EMAILJS_SERVICE, EMAILJS_TEMPLATE, form.current, {
      publicKey: EMAILJS_PUBLICKEY,
    })
    .then(
      () => {
        isSuccess = true;
      },
      (error) => {
        console.error("FAILED...", error.text);
        isSuccess = false;
      }
    );
  return isSuccess;
};

export default EmailService;
