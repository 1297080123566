import { Link } from "react-router-dom";
import NavBtn from "./NavBtn";
import ImgProfil from "./ImgProfil";
import { useState } from "react";
import Modal from "./Modal";
import ContactForm from "./contact/ContactForm";

const Banner = ({ cmd }) => {
  const [isOpenContactForm, setIsOpenContactForm] = useState(false);

  return (
    <>
      {isOpenContactForm && (
        <Modal
          closeCmd={() => setIsOpenContactForm(false)}
          content={<ContactForm />}
        />
      )}
      <div className="banner anime-me">
        <i className="banner--close-btn fa-solid fa-xmark" onClick={cmd}></i>
        <div className="banner--bg">
          <ImgProfil />
          <div className="banner--nav">
            <NavBtn name="A propos" path="/" cmd={cmd} />
            <NavBtn name="Mes projets" path="/projects" cmd={cmd} />
          </div>

          <div className="banner--medias">
            <div
              className="banner--medias-item"
              onClick={() => setIsOpenContactForm(true)}
            >
              <i className="fa-solid fa-envelope"></i>
            </div>

            {/* <Link
            to={"tel:+33641551682"}
            target="_blank"
            className="banner--medias-item"
          >
            <i className="fa-solid fa-phone"></i>
          </Link> */}
            <Link
              to={"https://github.com/MaeRiz/"}
              target="_blank"
              className="banner--medias-item"
            >
              <i className="fa-brands fa-github"></i>
            </Link>
            <Link
              to={"https://www.linkedin.com/in/mahery-gonin-5a977016b/"}
              target="_blank"
              className="banner--medias-item"
            >
              <i className="fa-brands fa-linkedin"></i>
            </Link>
          </div>
          <p>Handmade by Mahery with ❤️</p>
        </div>
      </div>
    </>
  );
};

export default Banner;
