import { useRef, useState } from "react";
import FormFields from "./FormFields";
import ReCAPTCHA from "react-google-recaptcha";
import EmailService from "../../utils/EmailService";
import SuccessSend from "./SuccessSend";
import ErrorSend from "./ErrorSend";
import Loading from "../Loading";

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_SITEKEY;

const ContactForm = () => {
  const [formFields, setFormFields] = useState({
    firstname: null,
    email: null,
    lastname: null,
    enterprise: null,
    message: null,
    tel: null,
    recaptcha: null,
  });
  const [correctForm, setCorrectForm] = useState(true);
  const [ShowSendMessage, setShowSendMessage] = useState(null);
  const formRef = useRef();

  const handleChangeField = (id, text) => {
    setFormFields({ ...formFields, [id]: text });
  };

  const formSubmit = () => {
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(formFields)) {
      if (value === null) {
        setCorrectForm(false);
        return null;
      }
    }
    setCorrectForm(true);
    setShowSendMessage(<Loading />);
    EmailService(formRef).then((response) => {
      if (response === true) {
        setShowSendMessage(<SuccessSend />);
      } else if (response === false) {
        setShowSendMessage(<ErrorSend />);
      }
    });
  };

  return (
    <div className="contact-form modal--content">
      {ShowSendMessage ? (
        ShowSendMessage
      ) : (
        <>
          <h3>Me contacter</h3>
          <form ref={formRef}>
            <div className="form-fields-names">
              <FormFields
                id="firstname"
                type="name"
                label="Prénom"
                foncChange={handleChangeField}
              />
              <FormFields
                id="lastname"
                type="name"
                label="Nom"
                foncChange={handleChangeField}
              />
            </div>

            <FormFields
              id="enterprise"
              type="text"
              label="Entreprise"
              foncChange={handleChangeField}
            />
            <FormFields
              id="email"
              type="email"
              label="Email"
              foncChange={handleChangeField}
            />
            <FormFields
              id="tel"
              type="tel"
              label="Téléphone"
              foncChange={handleChangeField}
            />
            <FormFields
              id="message"
              type="textarea"
              label="Message"
              foncChange={handleChangeField}
            />
          </form>
          <ReCAPTCHA
            sitekey={RECAPTCHA_KEY}
            onChange={(e) => {
              if (e) {
                handleChangeField("recaptcha", e);
              }
            }}
          />
          <div
            className={
              correctForm ? "form-submit-btn" : `form-submit-btn error`
            }
          >
            <p
              onClick={() => {
                formSubmit();
              }}
            >
              Envoyer{" "}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default ContactForm;
