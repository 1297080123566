import { NavLink } from "react-router-dom";

const NavBtn = ({ name, path, cmd }) => {
  return (
    <NavLink to={path} onClick={cmd} className="nav-btn">
      <p>{name}</p>
    </NavLink>
  );
};

export default NavBtn;
