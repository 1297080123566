import { ReactComponent as Img } from "../assets/imgs/not-found.svg";

const NotFound = () => {
  document.title = "404 Not Found - Mahery Gonin";
  return (
    <div className="notfound-page">
      <div className="notfound-page--svg-container">
        <Img />
      </div>
    </div>
  );
};

export default NotFound;
