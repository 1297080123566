import ProfilPic from "../assets/imgs/profil_pic.png";

const ImgProfil = ({ name, size, customClass, cmd }) => {
  return (
    <div className={customClass} onClick={cmd}>
      <div
        className="imgprofil--img"
        style={{ height: `${size}px`, width: `${size}px` }}
      >
        <div className="imgprofil--img-dark-circle"></div>
        <div className="imgprofil--img-green-circle"></div>
        <img
          className="imgprofil--img-profil-pic"
          src={ProfilPic}
          alt="Profil Mahery Gonin"
        />
      </div>
      {name ? <h4 className="imgprofil--name">Mahery Gonin</h4> : null}
    </div>
  );
};

ImgProfil.defaultProps = {
  name: true,
};

export default ImgProfil;
