import { useEffect, useState } from "react";
import ImgProfil from "./ImgProfil";
import Banner from "./Banner";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
//import {  } from "react-router";

const MobileHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showGoBackBtn, setShowGoBackBtn] = useState(false);
  let { pathname } = useLocation();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (pathname === "/") {
      handdleOpen();
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (matchPath({ path: "/projects/:id" }, pathname)) {
      setShowGoBackBtn(true);
    } else {
      setShowGoBackBtn(false);
    }
  }, [pathname]);

  const handdleOpen = () => {
    if (isOpen) {
      setIsOpen(false);
      document.body.style.overflow = "auto";
    } else {
      setIsOpen(true);
      document.body.style.overflow = "hidden";
    }
  };

  return (
    <>
      {isOpen ? (
        <Banner cmd={() => handdleOpen()} />
      ) : (
        <div className="mobile-header">
          <div className="mobile-header-btn" onClick={() => handdleOpen()}>
            <ImgProfil
              customClass="mobile-header--img"
              name={false}
              size={60}
            />
            <i className="fa-solid fa-bars"></i>
          </div>
          {showGoBackBtn ? (
            <div
              className="mobile-header--goback"
              onClick={() => {
                goBack();
              }}
            >
              <i className="fa-solid fa-angle-left"></i>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default MobileHeader;
