import AboutComp from "../components/AboutComp";
import TitlePage from "../components/TitlePage";

import { AboutMe } from "../assets/data/aboutme";
import { useRef } from "react";
import { useMediaQuery } from "react-responsive";

const Welcome = () => {
  const isSmallScreen = useMediaQuery({
    query: "(max-width: 1150px)",
  });
  const devRef = useRef(null);
  const assoRef = useRef(null);
  const infoRef = useRef(null);
  const Refs = [devRef, assoRef, infoRef];

  document.title = "A propos - Mahery Gonin";

  return (
    <>
      <div className="welcome-page">
        <TitlePage text="A propos de moi - Développeur Full-Stack" />
        <div className="welcome-page--nav">
          {AboutMe.map((item, index) => (
            <p
              key={index}
              onClick={() =>
                Refs[index].current.scrollIntoView({
                  block: `${isSmallScreen ? "start" : "center"}`,
                })
              }
            >
              {item.title}
            </p>
          ))}
        </div>
        <div className="welcome-page--content">
          {AboutMe.map((item, index) => (
            <AboutComp key={index} item={item} giveRef={Refs[index]} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Welcome;
