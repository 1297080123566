import { ReactComponent as Img } from "../../assets/imgs/failsend.svg";

const ErrorSend = () => {
  return (
    <div className="form-send-thanks">
      <Img />
      <h4 className="form-send-thanks--title">Erreur !</h4>
      <p className="form-send-thanks--msg">
        Quelque chose s'est mal passé pendant l'envoi.
        <br />
        Merci de réessayer dans un instant.
      </p>
    </div>
  );
};

export default ErrorSend;
